<template>
  <div style="position: absolute; left: 24px">
    <div class="textFive">
      <p>您已成功提交申请，我们将尽快对您的资料进行验证审核。</p>
      <p>
        您稍后可以在“我的融资"界面查看融资进度及结果。
        <!-- 确认您需要寻找并邀请至少三位担保人来完成整个放款额度预审流程。点击“确认”后，可在线查看并邀请意向担保人 -->
      </p>
    </div>
    <el-button class="btn btn4" type="primary" @click="next">确认</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    next() {
      // if (this.$parent.query.financingType === 3) {
      //   this.$parent.next();
      // } else {
        this.$router.push({ name: "wdrz" });
      // }
    }
  }
};
</script>

<style lang="less" scoped>
.btn4 {
  position: absolute;
  left: 300px;
  top: 170px;
}
.textFive {
  font-family: MicrosoftYaHei;
  font-size: 16px;
  color: #333333;
  width: 500px;
  text-align: center;
  position: absolute;
  left: 170px;
  top: 40px;
}
</style>
