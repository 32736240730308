<template>
  <div style="position: absolute; left: 24px">
    <el-form
      label-position="right"
      label-width="200px"
      :rules="rules"
      ref="formThree"
      :model="formThree"
    >
      <el-form-item prop="propertyType" label="产权类型">
        <el-select
          v-model="formThree.propertyType"
          placeholder="请选择产权类型"
        >
          <el-option
            v-for="item in options"
            :key="item.lx"
            :label="item.lx"
            :value="item.bh"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="面积"
        prop="propertyArea"
        style="position: absolute; top: 0px; left: 325px"
      >
        <el-input
          style="width: 221px"
          v-model="formThree.propertyArea"
        ></el-input>
        <span style="margin-left: 15px">亩</span>
      </el-form-item>
      <el-form-item prop="propertyAddress" label="产权地址">
        <el-input
          style="width: 597px"
          v-model="formThree.propertyAddress"
        ></el-input>
      </el-form-item>
      <el-form-item prop="surroundingInfo" label="周围环境信息">
        <el-input
          type="textarea"
          :rows="6"
          placeholder="请输入周围环境信息"
          v-model="formThree.surroundingInfo"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <el-button class="btn btn1" type="primary" @click="next">下一步</el-button>
  </div>
</template>

<script>
import { getTypeList } from "@/api/ApplicationsFor";
import { checkSpecificKey } from "@/js/utils/rules";
import { getPropertyType } from '@/api/exchange'
export default {
  props: ["type"],
  data() {
    const validatePrice = (rule, value, callback) => {
      let reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{0,2})$/;
      if (!value) {
        callback(new Error("产权面积不能为空，"));
      } else if (value.split(".").length > 2) {
        callback(new Error("输入正确格式的产权面积")); //防止输入多个小数点
      } else {
        value = Math.round(value * Math.pow(10, 2)) / Math.pow(10, 2); //四舍五入
        value = Number(value).toFixed(2); //不足补位
        this.formThree.propertyArea = value;
        callback();
      }
    };
    var validateInput = (rule, value, callback) => {
      if (!checkSpecificKey(value)) {
        callback(new Error("不支持特殊符号"));
      } else {
        callback();
      }
    };
    return {
      options: [],
      value: "",
      formThree: {
        propertyType: "",
        propertyArea: "",
        propertyAddress: "",
        surroundingInfo: ""
      },
      rules: {
        propertyType: [
          // { required: true, message: "请选择产权类型", trigger: "change" }
        ],
        propertyArea: [
          {
            required: true,
            trigger: "blur",
            validator: validatePrice
          }
        ],
        propertyAddress: [
          { required: true, message: "请输入产权位置地址", trigger: "blur" },
          {
            min: 2,
            max: 100,
            message: "长度在 2 到 100 个字符",
            trigger: "blur"
          },
          {
            pattern: /^[\u4e00-\u9fa5A-Za-z0-9_-]+[\)）（，；\(\.\%。\u4e00-\u9fa5A-Za-z0-9_-]+[\u4e00-\u9fa5A-Za-z0-9_-]?$/,
            message: "不能含有特殊符号",
            trigger: "blur"
          }
        ],
        surroundingInfo: [
          { required: true, message: "请输入周围环境信息", trigger: "blur" },
          {
            min: 2,
            max: 200,
            message: "长度在 2 到 100 个字符",
            trigger: "blur"
          },
          {
            pattern: /^[\u4e00-\u9fa5A-Za-z0-9_-]+[\)）（，；\(\.\%。\u4e00-\u9fa5A-Za-z0-9_-]+[\u4e00-\u9fa5A-Za-z0-9_-]?$/,
            message: "不能含有特殊符号",
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    // this.getChooseData();
    this.initPropertyType()
  },
  methods: {
    async initPropertyType() {
      // 初始化产权类型
      let res = await getPropertyType();
      let {status, data} = res;
      if(status) {
        this.options = data;
      }
    },
    async getChooseData() {
      let parames = this.type;
      let res = await getTypeList(parames);
      this.options = res.data;
    },
    next() {
      this.$refs.formThree.validate(valid => {
        if (valid) {
          this.$parent.query.propertyType = this.formThree.propertyType;
          this.$parent.query.propertyArea = this.formThree.propertyArea;
          this.$parent.query.propertyAddress = this.formThree.propertyAddress;
          this.$parent.query.surroundingInfo = this.formThree.surroundingInfo;
          console.log(this.$parent.query);
          this.$parent.next();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.btn1 {
  position: absolute;
  left: 325px;
}
</style>
