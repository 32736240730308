
// :rules="rules"


<template>
  <div class="steps-mid-radio" style="position: absolute; left: 144px">
    <el-form label-position="right" :rules="rules"  ref="formOne" label-width="120px" :model="formOne">
      <el-form-item prop="loansPurpose" label="贷款用途">
         <el-radio-group v-model="formOne.loansPurpose">
      <el-radio v-for="(item, index) in radio"
          :key="index"
          :label="item.index"
          border>{{ item.name }}</el-radio>
    </el-radio-group>
      </el-form-item>
      <el-form-item prop="loansLimit" label="申请贷款额度">
        <el-input style="width: 233px" v-model.number="formOne.loansLimit"></el-input>
        <span style="margin-left: 10px">元</span>
      </el-form-item>
      <el-form-item prop="loansPeriod" label="申请贷款期限">
        <el-input style="width: 233px" v-model.number="formOne.loansPeriod"></el-input>
        <span style="margin-left: 10px">年</span>
      </el-form-item>
    </el-form>
    <el-button class="btn" type="primary" @click="next">下一步</el-button>
  </div>
</template>

<script>
export default {
  props:["type"],
  data() {
    return {
      radio: [
        {
          name: "日常生活消费",
          index: 1,
        },
        {
          name: "教育支出",
          index: 2,
        },
        {
          name: "提高生活质量",
          index: 3,
        },
        {
          name: "其它",
          index: 4,
        },
      ],
      formOne: {
        loansPurpose: "",
        loansLimit: "",
        loansPeriod: "",
      },
      rules: {
          loansPurpose: [
            { required: true, message: '请选择贷款用途', trigger: 'change' },
          ],
          loansLimit: [
            { required: true, message: '请输入申请贷款额度', trigger: 'blur' },
            { type: 'number', message: '必须为数字值'},
             { pattern: /^([1-9]\d{3,8}|1000,000,000)$/, message: '贷款金额为1000-1000,000,000元',trigger: 'blur'}
          ],
          loansPeriod: [
            { required: true, message: '请输入贷款年限', trigger: 'blur' },
            { type: 'number', message: '必须为数字值'},
            { pattern: /^([1-9]|[1-2]\d|30)$/, message: '范围在1-30',trigger: 'blur'}

          ]
        }
    };
  },
  methods: {
    next() {
       this.$refs.formOne.validate((valid) => {
          if (valid) {
            this.$parent.query.loansPurpose=this.formOne.loansPurpose
            this.$parent.query.loansLimit=this.formOne.loansLimit
            this.$parent.query.loansPeriod=this.formOne.loansPeriod
            console.log(this.$parent.query)
            this.$parent.next();
          } else {
            console.log('error submit!!');
            return false;
          }
        });

    },
  },
};
</script>

<style lang="less" scoped>
.btn {
  position: absolute;
  left: 180px;
}
</style>
