<template>
  <div>
    <div v-if="active === 0">
      <steps-zero></steps-zero>
    </div>
    <div v-if="active === 1">
      <steps-one></steps-one>
    </div>
    <div v-if="active === 2">
      <steps-two :type="type"></steps-two>
    </div>
    <div v-if="active === 3">
      <steps-three></steps-three>
    </div>
    <div v-if="active === 4">
      <div v-if="type===1 || type===3">
        <steps-four :type="type"></steps-four>
      </div>
      <div v-if="type===2">
        <StepsFourP></StepsFourP>
      </div>
      
    </div>
    <div v-if="active === 5">
      <div  v-if="type===1 || type===2">
        <steps-five></steps-five>
      </div>
      <div v-if="type===3">
        <StepsFiveE></StepsFiveE>
      </div>
    </div>  
  </div>
</template>

<script>
import StepsZero from "./stepsZero";
import StepsOne from "./stepsOne";
import StepsTwo from "./stepsTwo";
import StepsThree from "./stepsThree";
import StepsFour from "./stepsFour";
import StepsFourP from "./stepsFourPledge";
import StepsFive from "./stepsFive";
import StepsFiveE from "./stepsFiveE";
export default {
  components: {
    StepsFive,
    StepsFiveE,
    StepsFour,
    StepsFourP,
    StepsThree,
    StepsZero,
    StepsOne,
    StepsTwo,
  },
  props: ["active","type"],
  data() {
    return {
      AllId:"",
      query:{
        userId:"decad426f21ce9c6aacc9c172b636f0d",
        uploadImgId:"",
        loansPurpose:"",
        financingType:this.type,
        loansLimit:"",
        loansPeriod:"",
        applicantName:"",
        applicantContact:"",
        applicantAddress:"",
        applicantUnitCode:"",
        applicantUnitAddress:"",
        propertyAddress:"",
        surroundingInfo:"",
        contactName1:"",
        contactPhone1:"",
        contactAddress1:"",
        contactUnit1:"",
        contactName2:"",
        contactPhone2:"",
        contactAddress2:"",
        contactUnit2:"",
        contactName3:"",
        contactPhone3:"",
        contactAddress3:"",
        contactUnit3:"",
        contactName4:"",
        contactPhone4:"",
        contactAddress4:"",
        contactUnit4:"",
      }
    };
  },
  mounted(){},
  methods: {
    next() {
      this.$parent.next();
    },
  },
};
</script>

<style lang="less" scoped>
</style>
