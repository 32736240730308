<template>
  <!-- 步骤条 -->
  <div class="my-steps">
    <el-steps :active="active" align-center>
      <el-step v-for="(item, index) in steps" :key="item" :title="item">
        <i slot="icon">
          <img v-if="active === index" src="imgs/icon-active.png" alt="" />
          <img v-if="index < active" src="imgs/icon-finish.png" alt="" />
          <img v-if="index > active" src="imgs/icon-wait.png" alt="" />
        </i>
      </el-step>
    </el-steps>
    <slot></slot>
    <!-- <el-button type="primary" class="btn" @click="next">下一步</el-button> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0
    };
  },
  props: {
    steps: {
      type: Array,
      default: () => []
    },
    type1: {
      type: Number,
      default: 0
    }
  },
  methods: {
    next() {
      if (this.active < this.steps.length - 1) {
        this.active++;
      } else {
        if (this.type1 === 1) {
          this.$nextTick(() => {
            this.$parent.isSteps = false;
          });
        } else if (this.type1 === 2) {
          this.$nextTick(() => {
            this.$parent.isStepss = false;
          });
        } else {
          this.active = 0;
        }
      }
      this.$emit("getCurrentStep", this.active);
    }
  }
};
</script>

<style lang="less">
.my-steps {
  .el-step__icon.is-text {
    border: none;
  }
  .el-step__title.is-finish {
    color: #333333;
  }
  .el-step__title.is-process {
    color: #333333;
    font-weight: 100;
  }
  .el-step__title.is-wait {
    color: #333333;
  }
  .el-step__head {
    height: 33px;
  }
  .is-finish .el-step__icon,
  .is-wait .el-step__icon {
    width: 18px;
  }
  .is-process .el-step__icon {
    // 当前步骤图片样式
    width: 45px;
    height: 33px;
    padding-bottom: 6px;
  }
  .el-step__line-inner {
    // 已执行步骤线条样式
    border-width: 3px !important;
  }
  .el-step.is-horizontal .el-step__line {
    // 线条位置
    top: 8px;
  }
  .el-step.is-horizontal .el-step__line {
    // 未执行的线条样式
    height: 5px;
    background: #7fcff4;
  }
}
</style>
