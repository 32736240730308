<template>
  <div style="position: absolute; left: 24px">
    <el-form
      label-position="right"
      label-width="200px"
      :rules="rules"
      ref="formTwo"
      :model="formTwo"
    >
      <el-form-item prop="applicantName" :label="peopelTitle+'姓名'">
        <el-input
          style="width: 221px"
          v-model="formTwo.applicantName"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="peopelTitle+'联系方式'"
        prop="applicantContact"
        style="position: absolute; top: 0px; left: 375px"
      >
        <el-input
          style="width: 221px"
          v-model="formTwo.applicantContact"
        ></el-input>
      </el-form-item>
      <el-form-item prop="applicantAddress" :label="peopelTitle+'现居住地址'">
        <el-input
          style="width: 597px"
          v-model="formTwo.applicantAddress"
        ></el-input>
      </el-form-item>
      <el-form-item prop="applicantUnit" :label="peopelTitle+'现单位/集体名称'">
        <el-input v-model="formTwo.applicantUnit"></el-input>
      </el-form-item>
      <el-form-item prop="applicantUnitCode" :label="peopelTitle+'现单位/集体固定号码'">
        <el-input v-model="formTwo.applicantUnitCode"></el-input>
      </el-form-item>
      <el-form-item prop="applicantUnitAddress" :label="peopelTitle+'现单位/集体地址'">
        <el-input v-model="formTwo.applicantUnitAddress"></el-input>
      </el-form-item>
    </el-form>
    <el-button class="btn1" type="primary" @click="next">下一步</el-button>
  </div>
</template>

<script>
import { checkSpecificKey } from "@/js/utils/rules";
export default {
  props: ["type"],
  data() {
    var validateInput = (rule, value, callback) => {
      if (!checkSpecificKey(value)) {
        callback(new Error("不支持特殊符号"));
      } else {
        callback();
      }
    };
    return {
      peopelTitle:'',
      formTwo: {
        applicantName: "",
        applicantContact: "",
        applicantAddress: "",
        applicantUnit: "",
        applicantUnitCode: "",
        applicantUnitAddress: ""
      },
      rules: {
        applicantName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "长度在 2 到 50 个字符",
            trigger: "blur"
          },
          {
            validator: validateInput,
            message: "不能含有特殊字符",
            trigger: "blur"
          }
        ],
        applicantContact: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
            message: "非法的电话号码",
            trigger: "blur"
          },
          {
            min: 11,
            max: 11,
            message: "电话号码长度必须为11位数字",
            trigger: "blur"
          }
        ],
        applicantAddress: [
          {
            required: true,
            message: "请输入现居住地址",
            trigger: "blur"
          },
          {
            min: 2,
            max: 100,
            message: "长度在 2 到 100 个字符",
            trigger: "blur"
          },
          {
            pattern: /^[\u4e00-\u9fa5A-Za-z0-9_-]+[\)）（，；\(\.\%。\u4e00-\u9fa5A-Za-z0-9_-]+[\u4e00-\u9fa5A-Za-z0-9_-]?$/,
            message: "不能含有特殊符号",
            trigger: "blur"
          }
        ],
        applicantUnit: [
          {
            required: true,
            message: "请输入单位/集体名称",
            trigger: "blur"
          },
          {
            min: 2,
            max: 100,
            message: "长度在 2 到 100 个字符",
            trigger: "blur"
          },
          {
            pattern: /^[\u4e00-\u9fa5A-Za-z0-9_-]+[\)）（，；\(\.\%。\u4e00-\u9fa5A-Za-z0-9_-]+[\u4e00-\u9fa5A-Za-z0-9_-]?$/,
            message: "不能含有特殊符号",
            trigger: "blur"
          }
        ],
        applicantUnitCode: [
          {
            required: true,
            message: "请输入现单位/集体固定号码",
            trigger: "blur"
          },
          {
            pattern: /^0\d{2,3}-?\d{7,8}$/,
            message: "非法的固定电话号码",
            trigger: "blur"
          },
        ],
        applicantUnitAddress: [
          {
            required: true,
            message: "请输入现单位地址/集体地址",
            trigger: "blur"
          },
          {
            min: 2,
            max: 100,
            message: "长度在 2 到 100 个字符",
            trigger: "blur"
          },
          {
            pattern: /^[\u4e00-\u9fa5A-Za-z0-9_-]+[\)）（，；\(\.\%。\u4e00-\u9fa5A-Za-z0-9_-]+[\u4e00-\u9fa5A-Za-z0-9_-]?$/,
            message: "不能含有特殊符号",
            trigger: "blur"
          }
        ]
      }
    };
  },
  created(){
    this.peopelTitle= this.type==3?'担保人':'申请人'
  },
  methods: {
    next() {
      this.$refs.formTwo.validate(valid => {
        if (valid) {
          // console.log(this.formTwo)
          if (this.type === 3) {
            this.$parent.query.guaranteeName = this.formTwo.applicantName;
            this.$parent.query.guaranteeContact = this.formTwo.applicantContact;
            this.$parent.query.guaranteeAddress = this.formTwo.applicantAddress;
            this.$parent.query.guaranteeUnit = this.formTwo.applicantUnit;
            this.$parent.query.guaranteeUnitCode = this.formTwo.applicantUnitCode;
            this.$parent.query.guaranteeUnitAddress = this.formTwo.applicantUnitAddress;
          } else {
            this.$parent.query.applicantName = this.formTwo.applicantName;
            this.$parent.query.applicantContact = this.formTwo.applicantContact;
            this.$parent.query.applicantAddress = this.formTwo.applicantAddress;
            this.$parent.query.applicantUnit = this.formTwo.applicantUnit;
            this.$parent.query.applicantUnitCode = this.formTwo.applicantUnitCode;
            this.$parent.query.applicantUnitAddress = this.formTwo.applicantUnitAddress;
          }
          // console.log(this.$parent.query);
          this.$parent.next();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.btn1 {
  position: absolute;
  left: 325px;
}
</style>
