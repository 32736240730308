<template>
  <div>
    <div style="position: absolute; left: 24px">
      <p class="title">联系人{{ num }}</p>
      <p class="title-info">
        特别提示：需填写4位联系人信息，其中2位必须是直系亲属。（非必需知道贷款）
      </p>
      <el-form
        label-position="right"
        label-width="200px"
        ref="formFore"
        :rules="rules"
        :model="formFore"
      >
        <el-form-item
          prop="contactName"
          style="position: absolute; top: 90px; left: 0px"
          label="联系人姓名"
        >
          <el-input
            style="width: 221px"
            v-model="formFore.contactName"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="contactPhone"
          label="联系人手机号码"
          style="position: absolute; top: 90px; left: 375px"
        >
          <el-input
            style="width: 221px"
            v-model="formFore.contactPhone"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="contactAddress"
          style="position: absolute; top: 150px; left: 0px"
          label="联系人工作/家庭地址"
        >
          <el-input
            style="width: 597px"
            v-model="formFore.contactAddress"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="contactUnit"
          style="position: absolute; top: 210px; left: 0px"
          label="联系人工作单位名称"
        >
          <el-input
            style="width: 597px"
            v-model="formFore.contactUnit"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button class="btn2" type="primary" @click="next">下一步</el-button>
    </div>
  </div>
</template>

<script>
import { checkSpecificKey } from "@/js/utils/rules";
export default {
  data() {
    var validateInput = (rule, value, callback) => {
      if (!checkSpecificKey(value)) {
        callback(new Error("不支持特殊符号"));
      } else {
        callback();
      }
    };
    return {
      ArrFore: [],
      formFore: {
        contactName: "",
        contactPhone: "",
        contactAddress: "",
        contactUnit: ""
      },
      formFore1: {},

      num: 1,
      rules: {
        contactName: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
          {
            min: 2,
            max: 30,
            message: "长度在 2 到 30 个字符",
            trigger: "blur"
          },
          {
            validator: validateInput,
            message: "不能含有特殊字符",
            trigger: "blur"
          }
        ],
        contactPhone: [
          { required: true, message: "请输入联系人手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
            message: "非法的电话号码",
            trigger: "blur"
          },
          {
            min: 11,
            max: 11,
            message: "电话号码长度必须为11位数字",
            trigger: "blur"
          }
        ],
        contactAddress: [
          {
            required: true,
            message: "请输入联系人工作/家庭住址",
            trigger: "blur"
          },
          {
            min: 2,
            max: 100,
            message: "长度在 2 到 100 个字符",
            trigger: "blur"
          },
          {
            pattern: /^[\u4e00-\u9fa5A-Za-z0-9_-]+[\)）（，；\(\.\%。\u4e00-\u9fa5A-Za-z0-9_-]?$/,
            message: "不能含有特殊符号",
            trigger: "blur"
          }
        ],
        contactUnit: [
          {
            required: true,
            message: "请输入联系人工作单位名称",
            trigger: "blur"
          },
          {
            min: 2,
            max: 100,
            message: "长度在 2 到 100 个字符",
            trigger: "blur"
          },
          {
            validator: validateInput,
            message: "不能含有特殊字符",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    next() {
      this.$refs.formFore.validate(valid => {
        if (valid) {
          if (this.num === 1) {
            for (var i in this.formFore) {
              this.formFore1.contactName1 = this.formFore.contactName;
              this.formFore1.contactPhone1 = this.formFore.contactPhone;
              this.formFore1.contactAddress1 = this.formFore.contactAddress;
              this.formFore1.contactUnit1 = this.formFore.contactUnit;
            }
            this.num++;
            this.formFore = {};
          } else if (this.num === 2) {
            for (var i in this.formFore) {
              this.formFore1.contactName2 = this.formFore.contactName;
              this.formFore1.contactPhone2 = this.formFore.contactPhone;
              this.formFore1.contactAddress2 = this.formFore.contactAddress;
              this.formFore1.contactUnit2 = this.formFore.contactUnit;
            }
            this.num++;
            this.formFore = {};
          } else if (this.num === 3) {
            for (var i in this.formFore) {
              this.formFore1.contactName3 = this.formFore.contactName;
              this.formFore1.contactPhone3 = this.formFore.contactPhone;
              this.formFore1.contactAddress3 = this.formFore.contactAddress;
              this.formFore1.contactUnit3 = this.formFore.contactUnit;
            }
            this.num++;
            this.formFore = {};
          } else if (this.num === 4) {
            for (var i in this.formFore) {
              this.formFore1.contactName4 = this.formFore.contactName;
              this.formFore1.contactPhone4 = this.formFore.contactPhone;
              this.formFore1.contactAddress4 = this.formFore.contactAddress;
              this.formFore1.contactUnit4 = this.formFore.contactUnit;
            }
            this.num++;
            this.$parent.next();
            for (var i in this.formFore1) {
              this.$parent.query[i] = this.formFore1[i];
            }
            console.log(this.$parent.query);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.title {
  font-family: MicrosoftYaHei-Bold;
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  position: absolute;
  left: 150px;
  width: 100px;
  &-info {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #ff2e2e;
    position: absolute;
    left: 150px;
    top: 40px;
    width: 600px;
  }
}
.btn2 {
  position: absolute;
  left: 325px;
  top: 280px;
}
</style>
